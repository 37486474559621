import {
    Box,
    Button,
    CircularProgress,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fade,
    Grid,
    Hidden,
    Typography,
} from '@material-ui/core'
import clsx from 'clsx'
import Basket from 'components/Basket'
import ErrorBox from 'components/ErrorBox'
import InfoBox from 'components/InfoBox'
import MobileProgressList from 'components/MobileProgressList/MobileProgressList'
import ProgressList from 'components/ProgressList'
import VZFInput from 'components/VZFInput'
import * as DateFns from 'date-fns'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ViewType } from 'store/GeneralState/GeneralState.reducer'
import { AppState } from 'store/store'
import colors from 'theme/colors'
import { getTestIDOnViewType } from 'utils/TestIDs'
import { ETrackerCodeList, getETrackerCodeByPage } from 'utils/etrackerCssCodes'
import { basketCalculation } from 'utils/testable/basketCalculation'
import { updateProgressList } from 'utils/testable/updateProgressList'
import InfoTooltip from '../InfoTooltip'
import { useViewWrapperReducer } from './useViewWrapperReducer'

export interface ViewWrapperProps {
    children: JSX.Element
    disabledSubmit: boolean
    header: string
    subHeader: string
    submitButtonText?: string
    submitRef?: ((instance: HTMLButtonElement | null) => void) | React.RefObject<HTMLButtonElement> | null | undefined
    viewType: ViewType
    disableBasket?: boolean
    disableButtons?: boolean
    tooltipText?: string
    errorCategory?: string | ViewType
    disableBackButton?: boolean
    onSubmitAlt?: () => void
    enableVZFInput?: boolean
    vzf?: string
    onChangeVZF?: (payload: string) => void
    vzfLoading?: boolean
    vzfNoResult?: boolean
    onClickCheckVZF?: () => void
}

const ViewWrapper = (props: ViewWrapperProps): JSX.Element => {
    const { t } = useTranslation()
    const {
        children,
        disabledSubmit,
        header,
        subHeader,
        submitButtonText,
        submitRef,
        tooltipText,
        viewType,
        disableBasket,
        disableButtons,
        errorCategory,
        disableBackButton,
        onSubmitAlt,
        enableVZFInput,
        vzf,
        onChangeVZF,
        vzfLoading,
        vzfNoResult,
        onClickCheckVZF,
    } = props
    const {
        availabilityCheck,
        generalState,
        bankDetails,
        loadState,
        minHeight,
        onSubmit,
        onBack,
        errors,
        B2B,
        vzfID,
        nextPageType,
        displayVPPProcesInfo,
        salesPartner,
        closeVPPOrderProcessInfo,
        currentPageData,
        infoBoxURL,
    } = useViewWrapperReducer()
    let newErrorCategory = errorCategory
    if (newErrorCategory === undefined && viewType === ViewType.AVAILABILITY_CHECK && errors.length > 0) {
        newErrorCategory = ViewType.PRODUCT_CATEGORIES_SELECTION
    }
    const { customizeJsData, clientData, startOfContract } = useSelector((appState: AppState) => {
        return {
            customizeJsData: appState.generalState.customizeJsData,
            clientData: appState.generalState.clientData,
            startOfContract: appState.generalState.startOfContract,
        }
    })

    let contractStartDate = new Date()
    if (startOfContract && startOfContract instanceof Date) {
        contractStartDate = startOfContract
    } else if (typeof startOfContract === 'string') {
        contractStartDate = new Date(startOfContract)
    }

    let labelBtnNext = t(viewType + 'ButtonText')
    if (
        nextPageType === ViewType.CONTACT_DATA ||
        nextPageType === ViewType.LANDLINE_CONTRACT_OPTIONS ||
        nextPageType === ViewType.PORTABILITY_DETAILS ||
        nextPageType === ViewType.BANK_DETAILS ||
        nextPageType === ViewType.INSTALLATION_DETAILS ||
        nextPageType === ViewType.DESIRED_DATE ||
        nextPageType === ViewType.MANUAL_VOUCHER_REDEMPTION ||
        nextPageType === ViewType.ORDER_OVERVIEW ||
        nextPageType === ViewType.ORDER_CONFIRMATION
    ) {
        labelBtnNext = t('labelNextBtn.' + nextPageType)
    }

    const etrackerCode = getETrackerCodeByPage(
        currentPageData,
        vzf !== undefined && vzf.length > 0 && onSubmitAlt !== undefined,
    )

    return (
        <Box minHeight={minHeight} paddingLeft={3} pr={{ xs: 3, sm: 6 }} paddingTop={2} paddingBottom={2}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={2} />
                <Grid item xs={12} md={10}>
                    <Hidden mdUp={true}>
                        <MobileProgressList {...updateProgressList(generalState, B2B, t)} />
                    </Hidden>
                    <Typography
                        {...getTestIDOnViewType(viewType, 'HEADER_TYPOGRAPHY')}
                        variant={'h1'}
                        className={clsx({ TextError: !loadState.loading && loadState.errors })}
                        dangerouslySetInnerHTML={{
                            __html: !loadState.loading && loadState.errors ? t(loadState.errors) : t(header),
                        }}
                    />
                    <Typography {...getTestIDOnViewType(viewType, 'SUBHEADER_TYPOGRAPHY')} variant={'h4'}>
                        {t(subHeader)}{' '}
                        {tooltipText && (
                            <Box display={'inline'}>
                                <Box display={'inline'} justifyContent={{ xs: 'flex-end', sm: 'center' }}>
                                    <InfoTooltip
                                        title={tooltipText}
                                        iconStyle={{ verticalAlign: 'middle' }}
                                        placement={'bottom-end'}
                                    />
                                </Box>
                            </Box>
                        )}
                    </Typography>
                </Grid>
                <Hidden smDown={true}>
                    <Grid item xs={12} md={2}>
                        <ProgressList {...updateProgressList(generalState, B2B, t)} />
                    </Grid>
                </Hidden>
                <Grid item xs={12} md={10}>
                    <Grid container item xs={12} lg={10} xl={9} direction={'column'} justifyContent={'space-between'}>
                        <Fade
                            in={loadState.loading}
                            timeout={250}
                            unmountOnExit={true}
                            exit={false}
                            disableStrictModeCompat={true}
                        >
                            <Box
                                height={1}
                                width={1}
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                marginTop={5}
                                marginBottom={{ xs: 5, sm: 0 }}
                            >
                                <CircularProgress size={80} />
                            </Box>
                        </Fade>
                        <Fade
                            in={!loadState.loading}
                            timeout={250}
                            unmountOnExit={true}
                            exit={false}
                            disableStrictModeCompat={true}
                        >
                            <>
                                {children}
                                <Collapse
                                    in={errors.length > 0}
                                    unmountOnExit={true}
                                    timeout={250}
                                    disableStrictModeCompat={true}
                                >
                                    <Grid container item xs={12}>
                                        <ErrorBox errors={errors} categoryToShow={newErrorCategory} />
                                    </Grid>
                                </Collapse>
                                {!disableButtons && (
                                    <Box width={1} marginTop={2} display={'flex'} justifyContent={'space-between'}>
                                        <Grid container justifyContent={'space-between'}>
                                            <Grid item xs={12} sm>
                                                {disableBackButton !== true && (
                                                    <Button
                                                        disableFocusRipple={true}
                                                        {...getTestIDOnViewType(viewType, 'BACK_BUTTON')}
                                                        disabled={generalState.currentPage === 0}
                                                        className={clsx(['FormButton', 'BackButton'])}
                                                        onClick={onBack}
                                                    >
                                                        {t('goBack')}
                                                    </Button>
                                                )}
                                            </Grid>
                                            <Grid container item xs={12} sm justifyContent={'flex-end'}>
                                                <Button
                                                    ref={submitRef}
                                                    disableFocusRipple={true}
                                                    {...getTestIDOnViewType(viewType, 'SUBMIT_BUTTON')}
                                                    disabled={disabledSubmit}
                                                    className={clsx(['FormButton', 'NextButton', etrackerCode])}
                                                    onClick={onSubmitAlt ? onSubmitAlt : onSubmit}
                                                >
                                                    {submitButtonText ? submitButtonText : labelBtnNext}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )}
                                {enableVZFInput &&
                                    vzf !== undefined &&
                                    onClickCheckVZF !== undefined &&
                                    onChangeVZF !== undefined &&
                                    vzfLoading !== undefined &&
                                    vzfNoResult !== undefined && (
                                        <>
                                            <Box
                                                width={1}
                                                marginTop={2}
                                                display={'flex'}
                                                justifyContent={'space-between'}
                                            >
                                                <Grid container justifyContent={'space-between'}>
                                                    <VZFInput
                                                        vzf={vzf}
                                                        loading={vzfLoading}
                                                        onEnter={onClickCheckVZF}
                                                        noResult={vzfNoResult}
                                                        onChangeVZF={onChangeVZF}
                                                    />
                                                </Grid>
                                            </Box>
                                            <Box
                                                width={1}
                                                marginTop={2}
                                                display={'flex'}
                                                justifyContent={'space-between'}
                                            >
                                                <Grid container justifyContent={'space-between'}>
                                                    <Grid item xs={12} sm></Grid>
                                                    <Grid container item xs={12} sm justifyContent={'flex-end'}>
                                                        <Button
                                                            ref={submitRef}
                                                            disableFocusRipple={true}
                                                            {...getTestIDOnViewType(viewType, 'SUBMIT_BUTTON_VZF')}
                                                            disabled={vzf.length === 0}
                                                            className={clsx([
                                                                'FormButton',
                                                                'NextButton',
                                                                ETrackerCodeList.vzf_continue,
                                                            ])}
                                                            onClick={onSubmitAlt}
                                                        >
                                                            {t('vzf.vzfCheck')}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </>
                                    )}
                                {customizeJsData && (
                                    <InfoBox
                                        globalConfiguration={customizeJsData.globalConfiguration}
                                        currentPath={currentPageData.path}
                                        backgroundColor={colors.lightGray}
                                        data={{
                                            company: clientData?.company,
                                            date: DateFns.format(contractStartDate, 'dd.MM.yyyy'),
                                            infoBoxURL: infoBoxURL,
                                        }}
                                    />
                                )}
                            </>
                        </Fade>
                    </Grid>
                    {!disableBasket && (
                        <Basket
                            vzfID={vzfID}
                            {...basketCalculation(availabilityCheck, bankDetails, generalState, B2B, false)}
                        />
                    )}
                </Grid>
            </Grid>
            <Dialog
                disableEscapeKeyDown={false}
                aria-labelledby={'customized-dialog-title'}
                open={displayVPPProcesInfo}
            >
                <DialogTitle id={'customized-dialog-title'}>{t('vppOrderProcess.infoHeadline')}</DialogTitle>
                <DialogContent style={{ padding: '24px' }} dividers>
                    <Typography>
                        {salesPartner && t('vppOrderProcess.infoBody')}
                        {!salesPartner && t('vppOrderProcess.infoBodyLogin')}
                    </Typography>
                </DialogContent>
                <DialogActions className={'center'}>
                    <Button variant={'outlined'} className={'primary'} onClick={closeVPPOrderProcessInfo}>
                        {salesPartner && t('ok')}
                        {!salesPartner && t('vppOrderProcess.login')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default ViewWrapper
